<template>
    <v-card class="ma-5">
        <v-form v-model="valid" ref="form">
            <v-container>
                <v-row class="my-4 align-center">
                    <h1 class="ms-2">{{ (lead.transformacion?.estado == 1) ? "DPTO COMERCIAL - SEGUIMIENTO VENTA" : "DPTO CAPTACIÓN - SEGUIMIENTO LEAD" }}</h1>
                    <v-spacer></v-spacer>
                    <v-btn @click="ret()" color="primary" class="me-2"><v-icon>mdi-arrow-left</v-icon>VOLVER</v-btn>
                    <v-btn v-if="idLead" @click="del" color="error" class="me-2"><v-icon>mdi-trash-can-outline</v-icon>ELIMINAR</v-btn>
                </v-row>
                <h3 class="primary--text">CONTACTO INICIAL</h3>
                <AuxInput class="mx-2 no-gutters" title="ENTRADA LEAD">
                    <v-menu ref="menuFecha" v-model="menuFecha" :return-value.sync="date" transition="scale-transition" offset-y
                        min-width="auto" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :value='lead.fecha != null ? ((new Date(lead.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(lead.fecha).getDate()).slice(-2)) + (new Date(lead.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(lead.fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker @click:date="menuFecha = false" no-title v-model="lead.fecha" :first-day-of-week="1" scrollable>
                        </v-date-picker>
                    </v-menu>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="VÍA ENTRADA">
                    <v-select filled v-model="lead.origen" dense hide-details :items="origen" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="CALIDAD">
                    <v-checkbox filled v-model="lead.calidad" dense hide-details class="v-superdense-input"></v-checkbox>
                </AuxInput>
                
                <v-divider class="mb-5"></v-divider>
                <h3 class="primary--text">DATOS</h3>
                <AuxInput class="mx-2 no-gutters" title="FECHA EVENTO">
                    <v-menu ref="menuFechaEv" v-model="menuFechaEv" :return-value.sync="date" transition="scale-transition" offset-y
                        min-width="auto" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field placeholder="POR DEFINIR" :value='lead.fechaEv ? ((new Date(lead.fechaEv).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(lead.fechaEv).getDate()).slice(-2)) + (new Date(lead.fechaEv).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(lead.fechaEv).getFullYear()) : null' append-icon="mdi-calendar" @input="lead.fechaEv = null" clearable filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker @click:date="menuFechaEv = false" no-title v-model="lead.fechaEv" :first-day-of-week="1" scrollable>
                        </v-date-picker>
                    </v-menu>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="HORARIO">
                    <v-select v-model="lead.horario" filled dense hide-details="" :items="horarios" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="NOMBRE PAREJA">
                    <v-text-field v-model='lead.clientes' append-icon="mdi-account-multiple" filled hide-details="" dense></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="NOMBRE Y APELLIDOS NOVIO">
                    <v-text-field v-model='lead.novio' append-icon="mdi-account-multiple" filled hide-details="" dense></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="NOMBRE Y APELLIDOS NOVIA">
                    <v-text-field v-model='lead.novia' append-icon="mdi-account-multiple" filled hide-details="" dense></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="TELÉFONO NOVIO">
                    <v-text-field v-model='lead.tlf' append-icon="mdi-phone" filled hide-details="" dense></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="TELÉFONO NOVIA">
                    <v-text-field v-model='lead.tlfNovia' append-icon="mdi-phone" filled hide-details="" dense></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="EMAIL NOVIO">
                    <v-text-field v-model='lead.email' append-icon="mdi-at" filled hide-details="" dense></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="EMAIL NOVIA">
                    <v-text-field v-model='lead.emailNovia' append-icon="mdi-at" filled hide-details="" dense></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="ESPACIO">
                    <v-select filled v-model="lead.espacio" append-icon="mdi-home" dense hide-details :items="espacio" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="CIUDAD CATERING" v-if="lead.espacio == 7 || lead.espacio == 6">
                    <v-text-field v-model='lead.ciudadCat' append-icon="mdi-map-marker" filled hide-details="" dense></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="ADULTOS">
                    <v-text-field v-model='lead.comensales' append-icon="mdi-human-male" filled hide-details="" dense></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="NIÑOS">
                    <v-text-field v-model='lead.ninos' append-icon="mdi-human-male" filled hide-details="" dense></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="QUIEN RESERVA">
                    <v-select clearable filled v-model="lead.reserva" dense hide-details :items="reserva" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="NOTA INICIAL/OBSERVACIONES">
                    <v-textarea v-model='lead.notaInicial' append-icon="mdi-note-edit" filled hide-details="" dense></v-textarea>
                </AuxInput>

                <AuxInput class="mx-2 no-gutters" title="FICHA NOVIOS">
                    <v-checkbox filled v-model="showFicha" dense hide-details class="v-superdense-input"></v-checkbox>
                </AuxInput>
                <template v-if="showFicha">
                    <AuxInput class="mx-2 no-gutters" title="CIUDAD NACIMIENTO NOVIO">
                        <v-text-field v-model='lead.ciudadNacimientoNovio' append-icon="mdi-city" filled hide-details="" dense></v-text-field>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="CIUDAD NACIMIENTO NOVIA">
                        <v-text-field v-model='lead.ciudadNacimientoNovia' append-icon="mdi-city" filled hide-details="" dense></v-text-field>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="PROCEDENCIA PRINCIPAL (NOVIO O NOVIA)">
                        <v-select clearable filled v-model="lead.procedencia" dense hide-details :items="procedencia" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="CÓMO NOS CONOCIERON">
                        <v-select clearable filled v-model="lead.origenReserva" dense hide-details :items="origenesReserva" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="BREVE DATO">
                        <v-text-field v-model='lead.breveDato' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="FICHA EN TRELLO">
                        <v-text-field v-model='lead.trelloLink' append-icon="mdi-trello" filled hide-details="" dense></v-text-field>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="TIPO ENLACE">
                        <v-select clearable filled v-model="lead.tipoEnlace" dense hide-details :items="tiposEnlace" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="UBICACIÓN ENLACE">
                        <v-text-field v-model='lead.ubicacionEnlace' append-icon="mdi-map-marker" filled hide-details="" dense></v-text-field>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="TIPO BODA">
                        <v-select clearable filled v-model="lead.tipoBoda" dense hide-details :items="tiposBoda" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="UBICACIÓN APERITIVO">
                        <v-select clearable filled v-model="lead.aperitivo" dense hide-details :items="aperitivos" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="NECESIDAD HABITACIONES">
                        <v-checkbox filled v-model="lead.habitaciones" dense hide-details class="v-superdense-input"></v-checkbox>
                    </AuxInput>
                </template>

                <template v-if="!(lead.transformacion?.estado == 1 || lead.transformacion?.estado == 4)">
                    <v-divider class="mb-5"></v-divider>
                    <h3 class="primary--text">SEGUIMIENTO</h3>
                    <h5 class="primary--text">EMAIL</h5>
                    <AuxInput :key="'email'+i" v-for="item,i in seguimientoEmail" class="ma-5 no-gutters" :title='item.title'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select :items="seguimientoAlt" :item-text="item => item.title" :item-value="item => item.val" clearable filled @input="setCaptacion($event, item.key)" :value="(lead[item.key] != null ? lead[item.key].contesta : null)" dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                            <template v-if="lead[item.key] != null && lead[item.key] != undefined">
                                <v-col class="pa-0 px-2" cols="12" md="4">
                                    <v-text-field v-model='lead[item.key].nota' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                                </v-col>
                                <v-col class="pa-0 px-2" cols="12" md="4">
                                    <v-menu v-if="lead[item.key] != null" v-model="menuEmail[i]" :return-value.sync="dateEmail[i]" transition="scale-transition" offset-y min-width="auto" :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field :class="lead[item.key].contesta ? 'reply' : 'noreply'" :value='lead[item.key].fecha != null ? ((new Date(lead[item.key].fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(lead[item.key].fecha).getDate()).slice(-2)) + (new Date(lead[item.key].fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(lead[item.key].fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker @click:date="menuEmail[i] = false" no-title v-model="lead[item.key].fecha" :first-day-of-week="1" scrollable>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </template>
                        </v-row>
                    </AuxInput>
                    <h5 class="primary--text">CALL</h5>
                    <AuxInput :key="'call'+i" v-for="item,i in seguimientoCall" class="ma-5 no-gutters" :title='item.title'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select :items="seguimiento" :item-text="item => item.title" :item-value="item => item.val" clearable filled @input="setCaptacion($event, item.key)" :value="(lead[item.key] != null ? lead[item.key].contesta : null)" dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                            <template v-if="lead[item.key] != null && lead[item.key] != undefined">
                                <v-col class="pa-0 px-2" cols="12" md="4">
                                    <v-text-field v-model='lead[item.key].nota' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                                </v-col>
                                <v-col class="pa-0 px-2" cols="12" md="4">
                                    <v-menu v-if="lead[item.key] != null" v-model="menuCall[i]" :return-value.sync="dateCall[i]" transition="scale-transition" offset-y
                                        min-width="auto" :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field :class="lead[item.key].contesta ? 'reply' : 'noreply'" :value='lead[item.key].fecha != null ? ((new Date(lead[item.key].fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(lead[item.key].fecha).getDate()).slice(-2)) + (new Date(lead[item.key].fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(lead[item.key].fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker @click:date="menuCall[i] = false" no-title v-model='lead[item.key].fecha' :first-day-of-week="1" scrollable>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </template>
                        </v-row>
                    </AuxInput>
                    <h5 class="primary--text">WHATSAPP</h5>
                    <AuxInput :key="'whats'+i" v-for="item,i in seguimientoWhats" class="ma-5 no-gutters" :title='item.title'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select :items="seguimientoAlt" :item-text="item => item.title" :item-value="item => item.val" clearable filled @input="setCaptacion($event, item.key)" :value="(lead[item.key] != null ? lead[item.key].contesta : null)" dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                            <template v-if="lead[item.key] != null && lead[item.key] != undefined">
                                <v-col class="pa-0 px-2" cols="12" md="4">
                                    <v-text-field v-model='lead[item.key].nota' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                                </v-col>
                                <v-col class="pa-0 px-2" cols="12" md="4">
                                    <v-menu v-if="lead[item.key] != null" v-model="menuWhats[i]" :return-value.sync="dateWhats[i]" transition="scale-transition" offset-y
                                        min-width="auto" :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field :class="lead[item.key].contesta ? 'reply' : 'noreply'" :value='lead[item.key].fecha != null ? ((new Date(lead[item.key].fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(lead[item.key].fecha).getDate()).slice(-2)) + (new Date(lead[item.key].fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(lead[item.key].fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker @click:date="menuWhats[i] = false" no-title v-model='lead[item.key].fecha' :first-day-of-week="1" scrollable>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </template>
                        </v-row>
                    </AuxInput>
                    <v-divider class="mb-5"></v-divider>
                    <h3 class="primary--text">TRANSFORMACIÓN LEAD</h3>
                    <AuxInput class="mx-2 no-gutters" title="ESTADO LEAD">
                        <v-select clearable filled @input="setTransformacion($event)" :value="(lead.transformacion != null ? lead.transformacion.estado : null)" append-icon="mdi-state-machine" dense hide-details :items="estado" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="MOTIVO" v-if="lead.transformacion?.estado == 2 || lead.transformacion?.estado == 3">
                        <template v-if="lead.transformacion?.estado == 2">
                            <v-select filled v-model="lead.perdida" dense hide-details :items="['ESPACIO','PRECIO','COMPETENCIA CONOCIDA','UBICACIÓN','OTROS']" class="v-superdense-input"></v-select>
                        </template>
                        <template v-if="lead.transformacion?.estado == 3">
                            <v-select filled v-model="lead.eliminada" dense hide-details :items="['NO DA SEÑALES','IMPOSIBLE DE REALIZAR','ASUNTO FAMILIAR','OTROS MOTIVOS']" class="v-superdense-input"></v-select>
                        </template>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="DETALLAR MOTIVO" v-if="[2,3].includes(lead.transformacion?.estado)">
                        <v-text-field v-model='lead.motivo' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="FECHA CITA" v-if="lead.transformacion?.estado == 1">
                        <v-menu ref="menuTransformacion" v-model="menuTransformacion" :return-value.sync="date" transition="scale-transition" offset-y
                            min-width="auto" :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field :value='lead.fechaCita != null ? ((new Date(lead.fechaCita).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(lead.fechaCita).getDate()).slice(-2)) + (new Date(lead.fechaCita).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(lead.fechaCita).getFullYear()) : null' append-icon="mdi-calendar" placeholder="POR DEFINIR" @input="lead.fechaCita = null" clearable readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker @click:date="menuTransformacion = false" no-title v-model="lead.fechaCita" :first-day-of-week="1" scrollable>
                            </v-date-picker>
                        </v-menu>
                    </AuxInput>
                </template>

                <template v-if="lead.transformacion?.estado == 1 || lead.transformacion?.estado == 4">
                    <v-divider class="mb-5"></v-divider>
                    <h3 class="primary--text">ESTADO DE VENTA</h3>
                    <h5 class="primary--text">CITA</h5>
                    <AuxInput class="mx-2 no-gutters" title="FECHA CITA" v-if="lead.transformacion?.estado == 1">
                        <v-menu ref="menuTransformacion" v-model="menuTransformacion" :return-value.sync="date" transition="scale-transition" offset-y
                            min-width="auto" :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field :value='lead.fechaCita != null ? ((new Date(lead.fechaCita).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(lead.fechaCita).getDate()).slice(-2)) + (new Date(lead.fechaCita).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(lead.fechaCita).getFullYear()) : null' append-icon="mdi-calendar" placeholder="POR DEFINIR" @input="lead.fechaCita = null" clearable readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker @click:date="menuTransformacion = false" no-title v-model="lead.fechaCita" :first-day-of-week="1" scrollable>
                            </v-date-picker>
                        </v-menu>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title='RESPONSABILIDAD'>
                        <v-select clearable filled v-model="lead.responsabilidad" append-icon="mdi-account-switch" dense hide-details :items="responsabilidad" :item-text="item => item.title" :item-value="item => item.val" :class="'v-superdense-input ' + ['pte','enviado'][lead.responsabilidad-1]"></v-select>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="PREVISIÓN">
                        <v-select clearable filled v-model="lead.prevision" append-icon="mdi-percent-circle-outline" dense hide-details :items="prevision" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                    </AuxInput>
                    <h5 class="primary--text">ESTADO VENTA</h5>
                    <AuxInput :key="'ppto'+i" v-for="ppto,i in lead.presupuestos" class="ma-5 no-gutters" :title='"PRESUPUESTO " + (i+1)' :firstcol="2" :lastcol="10">
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="3">
                                <v-select append-icon="mdi-file-sign" :items="solicitud" :item-text="item => item.title" :item-value="item => item.val" :value="ppto.estado" clearable filled @input="setPpto($event, i)" dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="2">
                                <v-menu v-model="menuPresupuesto[i]" :return-value.sync="datePresupuesto" transition="scale-transition" offset-y
                                    min-width="auto" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :class="['noreply', 'reply'][ppto.estado-1]" :value='ppto.fecha != null ? ((new Date(ppto.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(ppto.fecha).getDate()).slice(-2)) + (new Date(ppto.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(ppto.fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker @click:date="menuPresupuesto[i] = false" no-title v-model='ppto.fecha' :first-day-of-week="1" scrollable>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="2">
                                <v-text-field v-model='ppto.nota' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="2">
                                <v-select placeholder="introduce" clearable filled v-model="ppto.introduce" append-icon="mdi-archive-arrow-down-outline" dense hide-details :items="introduce" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="2">
                                <v-select placeholder="realiza" clearable filled v-model="ppto.realiza" append-icon="mdi-account" dense hide-details :items="realiza" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="1">
                                <v-checkbox filled v-model="ppto.linked" dense hide-details class="v-superdense-input centered" on-icon="mdi-link-box-variant" off-icon="mdi-link-box-variant-outline"></v-checkbox>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <AuxInput class="ma-5 no-gutters" :title='"PRESUPUESTO " + ((lead.presupuestos?.length || 0) + 1)' :firstcol="2" :lastcol="10">
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="3">
                                <v-select append-icon="mdi-plus" :items="solicitud" @change="addPresupuesto($event)" :item-text="item => item.title" :item-value="item => item.val" v-model="selectPpto" clearable filled dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <v-divider></v-divider>
                    <AuxInput :key="'condiciones'+i" v-for="cond,i in lead.condiciones" class="ma-5 no-gutters" :title='"CONDICIONES ESP. " + (i+1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select append-icon="mdi-home-switch" :items="solicitud" :item-text="item => item.title" :item-value="item => item.val" :value="cond.estado" clearable filled @input="setCondiciones($event, i)" dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-text-field v-model='cond.nota' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-menu v-model="menuCondiciones[i]" :return-value.sync="dateCondiciones" transition="scale-transition" offset-y
                                    min-width="auto" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :class="['noreply','reply'][cond.estado-1]" :value='cond.fecha != null ? ((new Date(cond.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(cond.fecha).getDate()).slice(-2)) + (new Date(cond.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(cond.fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker @click:date="menuCondiciones[i] = false" no-title v-model='cond.fecha' :first-day-of-week="1" scrollable>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <AuxInput class="ma-5 no-gutters" :title='"CONDICIONES ESP. " + ((lead.condiciones?.length || 0) + 1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select append-icon="mdi-plus" :items="solicitud" @change="addCondiciones($event)" :item-text="item => item.title" :item-value="item => item.val" v-model="selectCondiciones" clearable filled dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <v-divider></v-divider>
                    <AuxInput :key="'visita'+i" v-for="vst,i in lead.visitas" class="ma-5 no-gutters" :title='"VISITA ESPACIO " + (i+1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select append-icon="mdi-home-switch" :items="visita" :item-text="item => item.title" :item-value="item => item.val" :value="vst.estado" clearable filled @input="setVisita($event, i)" dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-text-field v-model='vst.nota' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-menu v-model="menuVisita[i]" :return-value.sync="dateVisita" transition="scale-transition" offset-y
                                    min-width="auto" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :class="['noreply','reply'][vst.estado-1]" :value='vst.fecha != null ? ((new Date(vst.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(vst.fecha).getDate()).slice(-2)) + (new Date(vst.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(vst.fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker @click:date="menuVisita[i] = false" no-title v-model='vst.fecha' :first-day-of-week="1" scrollable>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <AuxInput class="ma-5 no-gutters" :title='"VISITA ESPACIO " + ((lead.visitas?.length || 0) + 1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select append-icon="mdi-plus" :items="visita" @change="addVisita($event)" :item-text="item => item.title" :item-value="item => item.val" v-model="selectVisita" clearable filled dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <v-divider></v-divider>
                    <AuxInput :key="'llamada'+i" v-for="tmp,i in lead.llamadas" class="ma-5 no-gutters" :title='"DARLES TIEMPO " + (i+1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select append-icon="mdi-home-switch" :items="tiempo" :item-text="item => item.title" :item-value="item => item.val" :value="tmp.estado" clearable filled @input="setTiempo($event, i)" dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-text-field v-model='tmp.nota' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-menu v-model="menuLlamada[i]" :return-value.sync="dateLlamada" transition="scale-transition" offset-y
                                    min-width="auto" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :class="['noreply','reply'][tmp.estado-1]" :value='tmp.fecha != null ? ((new Date(tmp.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(tmp.fecha).getDate()).slice(-2)) + (new Date(tmp.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(tmp.fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker @click:date="menuLlamada[i] = false" no-title v-model='tmp.fecha' :first-day-of-week="1" scrollable>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <AuxInput class="ma-5 no-gutters" :title='"DARLES TIEMPO " + ((lead.visitas?.length || 0) + 1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select append-icon="mdi-plus" :items="tiempo" @change="addTiempo($event)" :item-text="item => item.title" :item-value="item => item.val" v-model="selectLlamada" clearable filled dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <v-divider></v-divider>
                    <AuxInput :key="'otros'+i" v-for="otro,i in lead.otros" class="ma-5 no-gutters" :title='"OTROS CONCEPTOS " + (i+1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select append-icon="mdi-home-switch" :items="tiempo" :item-text="item => item.title" :item-value="item => item.val" :value="otro.estado" clearable filled @input="setOtros($event, i)" dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-text-field v-model='otro.nota' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-menu v-model="menuOtros[i]" :return-value.sync="dateOtros" transition="scale-transition" offset-y
                                    min-width="auto" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :class="['noreply','reply'][otro.estado-1]" :value='otro.fecha != null ? ((new Date(otro.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(otro.fecha).getDate()).slice(-2)) + (new Date(otro.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(otro.fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker @click:date="menuOtros[i] = false" no-title v-model='otro.fecha' :first-day-of-week="1" scrollable>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <AuxInput class="ma-5 no-gutters" :title='"OTROS CONCEPTOS " + ((lead.otros?.length || 0) + 1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select append-icon="mdi-plus" :items="tiempo" @change="addOtros($event)" :item-text="item => item.title" :item-value="item => item.val" v-model="selectOtros" clearable filled dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <h5 class="primary--text">SEGUIMIENTO</h5>
                    <AuxInput :key="'callCom'+i" v-for="call,i in lead.seguimientoCallComercial" class="ma-5 no-gutters" :title='"LLAMADA " + (i+1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select :items="seguimiento" :item-text="item => item.title" :item-value="item => item.val" :value="call.estado" clearable filled @input="setSeguimiento($event, 'Call', i)" dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-text-field v-model='call.nota' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-menu v-model="menuCallComercial[i]" :return-value.sync="dateCallComercial" transition="scale-transition" offset-y
                                    min-width="auto" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :class="call.estado ? 'reply' : 'noreply'" :value='call.fecha != null ? ((new Date(call.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(call.fecha).getDate()).slice(-2)) + (new Date(call.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(call.fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker @click:date="menuCallComercial[i] = false" no-title v-model='call.fecha' :first-day-of-week="1" scrollable>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <AuxInput class="ma-5 no-gutters" :title='"LLAMADA " + ((lead.seguimientoCallComercial?.length || 0) + 1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select :items="seguimiento" @change="addCallComercial($event)" :item-text="item => item.title" :item-value="item => item.val" v-model="selectCallComercial" clearable filled dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <v-divider></v-divider>
                    <AuxInput :key="'emailCom'+i" v-for="email,i in lead.seguimientoEmailComercial" class="ma-5 no-gutters" :title='"EMAIL " + (i+1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select :items="seguimiento" :item-text="item => item.title" :item-value="item => item.val" :value="email.estado" clearable filled @input="setSeguimiento($event, 'Email', i)" dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-text-field v-model='email.nota' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-menu v-model="menuEmailComercial[i]" :return-value.sync="dateEmailComercial" transition="scale-transition" offset-y
                                    min-width="auto" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :class="email.estado ? 'reply' : 'noreply'" :value='email.fecha != null ? ((new Date(email.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(email.fecha).getDate()).slice(-2)) + (new Date(email.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(email.fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker @click:date="menuEmailComercial[i] = false" no-title v-model='email.fecha' :first-day-of-week="1" scrollable>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <AuxInput class="ma-5 no-gutters" :title='"EMAIL " + ((lead.seguimientoEmailComercial?.length || 0) + 1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select :items="seguimiento" @change="addEmailComercial($event)" :item-text="item => item.title" :item-value="item => item.val" v-model="selectEmailComercial" clearable filled dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <v-divider></v-divider>
                    <AuxInput :key="'whatsCom'+i" v-for="whats,i in lead.seguimientoWhatsComercial" class="ma-5 no-gutters" :title='"WHATS " + (i+1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select :items="seguimiento" :item-text="item => item.title" :item-value="item => item.val" :value="whats.estado" clearable filled @input="setSeguimiento($event, 'Whats', i)" dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-text-field v-model='whats.nota' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                            </v-col>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-menu v-model="menuWhatsComercial[i]" :return-value.sync="dateWhatsComercial" transition="scale-transition" offset-y
                                    min-width="auto" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :class="whats.estado ? 'reply' : 'noreply'" :value='whats.fecha != null ? ((new Date(whats.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(whats.fecha).getDate()).slice(-2)) + (new Date(whats.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(whats.fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker @click:date="menuWhatsComercial[i] = false" no-title v-model='whats.fecha' :first-day-of-week="1" scrollable>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <AuxInput class="ma-5 no-gutters" :title='"WHATS " + ((lead.seguimientoWhatsComercial?.length || 0) + 1)'>
                        <v-row>
                            <v-col class="pa-0 px-2" cols="12" md="4">
                                <v-select :items="seguimiento" @change="addWhatsComercial($event)" :item-text="item => item.title" :item-value="item => item.val" v-model="selectWhatsComercial" clearable filled dense hide-details class="v-superdense-input me-auto"></v-select>
                            </v-col>
                        </v-row>
                    </AuxInput>
                    <h3 class="primary--text">TRANSFORMACIÓN LEAD</h3>
                    <AuxInput class="mx-2 no-gutters" title="ESTADO LEAD">
                        <v-select clearable filled @input="setTransformacion($event)" :value="(lead.transformacion != null ? lead.transformacion.estado : null)" append-icon="mdi-state-machine" dense hide-details :items="estado" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                    </AuxInput>
                    <AuxInput class="mx-2 no-gutters" title="MOTIVO" v-if="[2,3].includes(lead.transformacion?.estado)">
                        <v-text-field v-model='lead.motivo' append-icon="mdi-note-edit" filled hide-details="" dense></v-text-field>
                    </AuxInput>
                </template>
                <v-btn color="primary" class="my-2" @click="save"><v-icon>mdi-floppy</v-icon>GUARDAR</v-btn>
            </v-container>
        </v-form>
        <ConfirmDialog ref="confirm" />
    </v-card>
</template>


<script>

import { req } from "@/utils/validations.js";
export default {
    props: ["idLead"],
    components: {
        AuxInput: () => import("@/components/AuxInput.vue"),
        ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    },
    data() {
        return {
            introduce:[
                {title:"LAURA", val : 1}, 
                {title:"BERTA", val : 2}, 
                {title:"ISABEL", val : 3}, 
                {title:"MONICA", val : 4}, 
                {title:"JAVI", val : 5}, 
                {title:"JOSE", val : 6}, 
                {title:"BELEN", val : 10}, 
                {title:"OTROS", val : 7}
            ],
            realiza:[
                {title:"LAURA", val : 1}, 
                {title:"BERTA", val : 2}, 
                {title:"ISABEL", val : 3}, 
                {title:"MONICA", val : 4}, 
                {title:"BELEN", val : 10}, 
                {title:"OTROS", val : 7}
            ],
            aperitivos:[
                {title:"JARDÍN PISCIS", val : 1}, 
                {title:"TERRAZA HOTEL", val : 2}, 
                {title:"PATIO LALANNE", val : 3}, 
                {title:"EMBARCADERO LAUS", val : 4}, 
                {title:"OTROS", val : 5}],
            horarios:[
                {title:"CO",val:1}, 
                {title:"CE",val:2},
                {title:"PTE",val:9}],
            procedencia:[
                {title:"MONZON", val : 1}, 
                {title:"CINCA MEDIO", val: 2}, 
                {title:"SOMONTANO",val:3}, 
                {title:"LITERA",val:4}, 
                {title:"OTROS HUESCA",val:5}, 
                {title:"OTROS FUERA",val:6}],
            reserva:[
                {title:"MONICA", val : 1}, 
                {title:"ISABEL", val : 2}, 
                {title:"LAURA", val : 3}, 
                {title:"BERTA", val : 4}, 
                {title:"JAVI", val : 5}, 
                {title:"BELEN", val : 10},
                {title:"OTROS", val : 6} 
            ],
            origenesReserva:[
                {title:"EXPERIENCIA", val : 2}, 
                {title:"RECOMENDACIÓN", val : 3}, 
                {title:"RRSS o PRENSA", val : 1}, 
                {title:"OTROS", val : 5}],
            tiposEnlace:[
                {title: "CIVIL", val : 1}, 
                {title:"RELIGIOSO", val : 2}],
            tiposBoda:[
                {title:"CLASICA", val : 1}, 
                {title:"INFORMAL", val : 2}, 
                {title:"SENCILLA", val : 3}],
            selectCallComercial: null,
            selectEmailComercial: null,
            selectWhatsComercial: null,
            selectPpto: null,
            selectVisita: null,
            selectLlamada: null,
            selectOtros: null,
            selectCondiciones: null,
            showFicha: null,
            lead: {},
            rules: { req },
            saving: false,
            menuFecha: false,
            menuTransformacion: false,
            menuFechaEv: false,
            menuVisita: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
            dateVisita: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menuPresupuesto: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
            datePresupuesto: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menuLlamada: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
            dateLlamada: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menuOtros: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
            dateOtros: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menuCondiciones: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
            dateCondiciones: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menuCall: [false,false,false],
            menuCallComercial: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
            menuEmail: [false,false],
            menuEmailComercial: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
            menuWhats: [false,false],
            menuWhatsComercial: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
            dateCall: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
            dateCallComercial: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateEmail: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
            dateEmailComercial: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateWhats: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
            dateWhatsComercial: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            origen:[
                {title : 'BODAS.NET CATERING', val : 1},
                {title : 'BODAS.NET PISCIS', val : 2},
                {title : 'BODAS.NET LAUS', val : 3},
                {title : 'BODAS.NET HOTEL', val : 4},
                {title : 'BODAS.NET MAS DE PONENT', val : 11},
                {title : 'BODAS.NET PANTICOSA', val : 12},
                {title : 'LALANNE DIRECTO', val : 5},
                {title : 'LAUS DIRECTO', val : 6},
                {title : 'WEB', val : 7},
                {title : 'EMAIL', val : 8},
                {title : 'TELÉFONO', val : 9},
                {title : 'INTERNO', val : 10},
            ],
            espacio:[
                {title: "PTE", val : 0},
                {title: "PISCIS", val : 1},
                {title: "HOTEL", val : 2}, 
                {title: "LALANNE", val : 3}, 
                {title: "LAUS", val : 4}, 
                {title: "PALACIO", val : 5}, 
                {title: "MAS DE PONENT", val : 16}, 
                {title: "LLOTJA", val : 8},
                {title: "VDV", val : 9}, 
                {title: "PANTICOSA", val : 50}, 
                {title: "SOMMOS", val : 60}, 
                {title: "CATERING ARAGÓN", val : 6}, 
                {title: "CATERING CATALUÑA", val : 7}
            ],
            solicitud:[
                {title : 'PTE ENVIAR', val : 1},
                {title : 'ENVIADO', val : 2},
            ],
            visita:[
                {title : 'PTE REALIZAR', val : 1},
                {title : 'REALIZADA', val : 2},
            ],
            tiempo:[
                {title : 'SI', val : 1},
                {title : 'NO', val : 2},
            ],
            responsabilidad:[
                {title : 'MASFARRE', val : 1},
                {title : 'PAREJA', val : 2},
            ],
            estado:[
                {title : 'TRANSFORMADA CITA', val : 1},
                {title : 'PERDIDA', val : 2},
                {title : 'ELIMINADA', val : 3},
                {title : 'GANADA', val : 4},
            ],
            seguimiento:[
                {title : 'RESPONDIDO', val : 1},
                {title : 'REALIZADA MF', val : 0},
            ],
            seguimientoAlt:[
                {title : 'RESPONDIDO', val : 1},
                {title : 'ENVIADO MF', val : 0},
            ],
            seguimientoEmail:[
                {key : 'email1', title : 'EMAIL 1'},
                {key : 'email2', title : 'EMAIL 2'},
                {key : 'email3', title : 'EMAIL 3'},
            ],
            seguimientoCall:[
                {key : 'call1', title : 'LLAMADA 1 '},
                {key : 'call2', title : 'LLAMADA 2'},
                {key : 'call3', title : 'LLAMADA 3'},
            ],
            seguimientoWhats:[
                {key : 'whats1', title : 'WHATS 1'},
                {key : 'whats2', title : 'WHATS 2'},
                {key : 'whats3', title : 'WHATS 3'},
            ],
            prevision:[
                {title : 'NEGATIVA', val : 0}, 
                {title : 'NEUTRA', val : 1},
                {title : 'POSITIVA', val : 2},
            ],
            specialChars:[
                {
                    name : "\u20ac", 
                    isMirrored: false,
                    isDigit: false,
                    isLetter: false,
                    isISOControl: false,
                    isDefined: true,
                    isSpaceChar: false,
                    isValidCodePoint: true,
                },
                {
                    name : "\u21ac", 
                    isMirrored: true,
                    isDigit: true,
                    isLetter: false,
                    isISOControl: false,
                    isDefined: true,
                    isSpaceChar: false,
                    isValidCodePoint: true,
                }
            ],
            valid: true,
        };
    },
    methods:{
        ret(){
            this.$router.push({ path: '/comercial' })
        },
        addPresupuesto(b){
            if(!this.lead.presupuestos){
                this.$set(this.lead,'presupuestos',[])
            }
            if (b == null) return
            this.$set(this.lead.presupuestos,this.lead.presupuestos.length,{
                estado: b,
                fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                nota: ''
            })
            this.$nextTick(() => {
                this.selectPpto = null
            });
        },
        addVisita(b){
            if(!this.lead.visitas){
                this.$set(this.lead,'visitas',[])
            }
            if (b == null) return
            this.$set(this.lead.visitas,this.lead.visitas.length,{
                estado: b,
                fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                nota: ''
            })
            this.$nextTick(() => {
                this.selectVisita = null
            });
        },
        addTiempo(b){
            if(!this.lead.llamadas){
                this.$set(this.lead,'llamadas',[])
            }
            if (b == null) return
            this.$set(this.lead.llamadas,this.lead.llamadas.length,{
                estado: b,
                fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                nota: ''
            })
            this.$nextTick(() => {
                this.selectLlamada = null
            });
        },
        addCondiciones(b){
            if(!this.lead.condiciones){
                this.$set(this.lead,'condiciones',[])
            }
            if (b == null) return
            this.$set(this.lead.condiciones,this.lead.condiciones.length,{
                estado: b,
                fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                nota: ''
            })
            this.$nextTick(() => {
                this.selectCondiciones = null
            });
        },
        addOtros(b){
            if(!this.lead.otros){
                this.$set(this.lead,'otros',[])
            }
            if (b == null) return
            this.$set(this.lead.otros,this.lead.otros.length,{
                estado: b,
                fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                nota: ''
            })
            this.$nextTick(() => {
                this.selectOtros = null
            });
        },
        addCallComercial(b){
            if(!this.lead.seguimientoCallComercial){
                this.$set(this.lead,'seguimientoCallComercial',[])
            }
            if (b == null) return
            this.$set(this.lead.seguimientoCallComercial,this.lead.seguimientoCallComercial.length,{
                estado: b,
                fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                nota: ''
            })
            this.$nextTick(() => {
                this.selectCallComercial = null
            });
        },
        addEmailComercial(b){
            if(!this.lead.seguimientoEmailComercial){
                this.$set(this.lead,'seguimientoEmailComercial',[])
            }
            if (b == null) return
            this.$set(this.lead.seguimientoEmailComercial,this.lead.seguimientoEmailComercial.length,{
                estado: b,
                fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                nota: ''
            })
            this.$nextTick(() => {
                this.selectEmailComercial = null
            });
        },
        addWhatsComercial(b){
            if(!this.lead.seguimientoWhatsComercial){
                this.$set(this.lead,'seguimientoWhatsComercial',[])
            }
            if (b == null) return
            this.$set(this.lead.seguimientoWhatsComercial,this.lead.seguimientoWhatsComercial.length,{
                estado: b,
                fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                nota: ''
            })
            this.$nextTick(() => {
                this.selectWhatsComercial = null
            });
        },
        setTransformacion(b){
            if (b == null){
                this.$delete(this.lead,"transformacion")
                return
            }
            this.$set(this.lead, 'transformacion', {estado : b, fecha : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)})
        },
        setSeguimiento(b, key, i){
            if (b == null){
                this.lead['seguimiento' + key + 'Comercial'].splice(i,1)
                return
            }
            if (this.lead['seguimiento' + key + 'Comercial']){
                this.$set(this.lead['seguimiento' + key + 'Comercial'][i],'estado', b)
                this.$set(this.lead['seguimiento' + key + 'Comercial'][i],'fecha', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))
                this.$set(this.lead['seguimiento' + key + 'Comercial'][i],'nota', (this.lead['seguimiento' + key + 'Comercial']?.nota || ''))
            }
            else {
                this.lead['seguimiento' + key + 'Comercial'] = []
            }
        },
        setPpto(b, i){
            if (b == null){
                this.lead['presupuestos'].splice(i,1)
                return
            }
            if (this.lead['presupuestos']){
                this.$set(this.lead['presupuestos'][i],'estado', b)
                this.$set(this.lead['presupuestos'][i],'fecha', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))
                this.$set(this.lead['presupuestos'][i],'nota', (this.lead['presupuestos']?.nota || ''))
            }
            else {
                this.lead['presupuestos'] = []
            }
        },
        setVisita(b, i){
            if (b == null){
                this.lead['visitas'].splice(i,1)
                return
            }
            if (this.lead['visitas']){
                this.$set(this.lead['visitas'][i],'estado', b)
                this.$set(this.lead['visitas'][i],'fecha', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))
                this.$set(this.lead['visitas'][i],'nota', (this.lead['visitas']?.nota || ''))
            }
            else {
                this.lead['visitas'] = []
            }
        },
        setTiempo(b, i){
            if (b == null){
                this.lead['llamadas'].splice(i,1)
                return
            }
            if (this.lead['llamadas']){
                this.$set(this.lead['llamadas'][i],'estado', b)
                this.$set(this.lead['llamadas'][i],'fecha', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))
                this.$set(this.lead['llamadas'][i],'nota', (this.lead['llamadas']?.nota || ''))
            }
            else {
                this.lead['llamadas'] = []
            }
        },
        setCondiciones(b, i){
            if (b == null){
                this.lead['condiciones'].splice(i,1)
                return
            }
            if (this.lead['condiciones']){
                this.$set(this.lead['condiciones'][i],'estado', b)
                this.$set(this.lead['condiciones'][i],'fecha', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))
                this.$set(this.lead['condiciones'][i],'nota', (this.lead['condiciones']?.nota || ''))
            }
            else {
                this.lead['condiciones'] = []
            }
        },
        setOtros(b, i){
            if (b == null){
                this.lead['otros'].splice(i,1)
                return
            }
            if (this.lead['otros']){
                this.$set(this.lead['otros'][i],'estado', b)
                this.$set(this.lead['otros'][i],'fecha', (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))
                this.$set(this.lead['otros'][i],'nota', (this.lead['otros']?.nota || ''))
            }
            else {
                this.lead['otros'] = []
            }
        },
        setItem(b, key){
            if (b == null){
                this.$delete(this.lead,key)
                return
            }
            this.$set(this.lead, key, {estado : b, fecha : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), nota: (this.lead[key]?.nota || '')})
        },
        setCaptacion(b, key){
            if (b == null){
                this.$delete(this.lead,key)
                return
            }
            if (key == "email1" && b) this.$set(this.lead, key, {contesta : b, fecha : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), nota: (this.lead[key]?.nota || 'ENVIADA FICHA DE CONTACTO Y SOLICITUD CITA')})
            else this.$set(this.lead, key, {contesta : b, fecha : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), nota: (this.lead[key]?.nota || '')})
        },
        setDecision(b){
            this.$set(this.lead, "decisionPareja", {decision : b, fecha : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)})
        },
        async del(){
            const confirmation =
                (await this.$refs.confirm.open(
                "Eliminar lead",
                "Se eliminará el lead. Esta acción NO se puede deshacer.",
                { color: "primary" }
                ));
            if (!confirmation) return;

            try {
                this.saving = true;
                const id = await axios({
                    method: 'DELETE',
                    url: `${process.env.VUE_APP_API_URL}/leads/${this.idLead}`,
                })
                this.saving = false;

                this.$root.$emit("snack", "Eliminado correctamente");
                this.$router.push({ name: 'operativa'}).catch(()=>{})

            } catch (err){
                this.saving = false;
                console.error(err);
                this.$root.$emit("snack", "No se ha podido eliminar correctamente");
            }
        },
        async save(skip) {
            if (!this.$refs.form.validate()) {
                this.$root.$emit("snack", "ERROR: Asegurate de haber rellenado los campos obligatorios");
                return;
            }
            const confirmation =
                skip ||
                (await this.$refs.confirm.open(
                "Guardar los cambios",
                "¿Estás seguro de que quieres guardar este lead?",
                { color: "primary" }
                ));
            if (!confirmation) return;
            try{
                this.saving = true;
                const id = await axios({
                    method: 'POST',
                    url: `${process.env.VUE_APP_API_URL}/leads/nuevo/`,
                    data: {
                        idLead : this.idLead,
                        datos : this.lead,
                    }
                })
                this.saving = false;

                this.$root.$emit("snack", "Se han guardado los cambios correctamente");
                this.$router.push({ path: '/comercial' }).catch(()=>{})

            } catch (err){
                this.saving = false;
                console.error(err);
                this.$root.$emit("snack", "No se han podido guardar los cambios");
            }
		},
        async getLead() {
            if (this.idLead){
                try {
                    const { data } = await axios({
                        method: 'GET',
                        url: `${process.env.VUE_APP_API_URL}/leads/${this.idLead}`,
                    })
                    this.$root.$emit("snack", "Lead cargado correctamente");
                    this.lead = data;
                    if (!this.lead.fecha) this.lead.fecha = new Date();
                    try {
                        this.lead.seguimientoEmailComercial = JSON.parse(this.lead.seguimientoEmailComercial)
                    } catch (err) {
                        this.lead.seguimientoEmailComercial = undefined
                    }
                    try {
                        this.lead.seguimientoCallComercial = JSON.parse(this.lead.seguimientoCallComercial)
                    } catch (err) {
                        this.lead.seguimientoCallComercial = undefined
                    }
                    try {
                        this.lead.seguimientoWhatsComercial = JSON.parse(this.lead.seguimientoWhatsComercial)
                    } catch (err) {
                        this.lead.seguimientoWhatsComercial = undefined
                    }
                    try {
                        this.lead.presupuestos = JSON.parse(this.lead.presupuestos)
                    } catch (err) {
                        this.lead.presupuestos = undefined
                    }
                    try {
                        this.lead.visitas = JSON.parse(this.lead.visitas)
                    } catch (err) {
                        this.lead.visitas = undefined
                    }
                    try {
                        this.lead.condiciones = JSON.parse(this.lead.condiciones)
                    } catch (err) {
                        this.lead.condiciones = undefined
                    }
                    try {
                        this.lead.otros = JSON.parse(this.lead.otros)
                    } catch (err) {
                        this.lead.otros = undefined
                    }
                    try {
                        this.lead.llamadas = JSON.parse(this.lead.llamadas)
                    } catch (err) {
                        this.lead.llamadas = undefined
                    }
                } catch (err){
                    this.saving = false;
                    console.error(err);
                    this.$root.$emit("snack", "No se han podido cargar los datos de este lead");
                }
            }
            else {
                this.lead.fecha = new Date().toISOString().split('T')[0];
            }
        }
    },
    mounted(){
        this.getLead()
    }
};
</script>

<style scoped>
.reply >>> input, .enviado >>> input{
    /* font-weight: 900 !important; */
    color: var(--v-success-base) !important
}
.noreply >>> input{
    /* font-weight: 900 !important; */
    color: var(--v-error-base) !important
}

.pte >>> input{
    /* font-weight: 900 !important; */
    color: var(--v-warning-base) !important
}
.v-input--checkbox.centered >>> .v-input__slot{
    justify-content:center !important;
    transform: scale(1.5,1.5)
}
</style>